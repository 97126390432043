import $ from 'jquery';

const faqHeadings = $('.FaqItem__heading');

faqHeadings.on('click', function () {
    const parentElement = $(this).closest('.FaqItem');
    parentElement.toggleClass('FaqItem--active').siblings().removeClass('FaqItem--active');
    const contentElement = parentElement.find('.FaqItem__content');

    if (contentElement.is(':visible')) {
        contentElement.slideUp();
    } else {
        contentElement
            .slideDown()
            .closest('.FaqItem')
            .siblings()
            .find('.FaqItem__content')
            .slideUp();
    }
});
