import $ from 'jquery';

const openModal = () => {
    $('#login-modal').addClass('flex');
    $('#login-modal').removeClass('hidden');
};

const closeModal = () => {
    $('#login-modal').removeClass('flex');
    $('#login-modal').addClass('hidden');
};

$('#login-modal-btn').on('click', openModal);
$('#close-login-modal-btn').on('click', closeModal);

$('body').on('keydown', (e) => {
    if (e.key === 'Escape') {
        closeModal();
    }
});
